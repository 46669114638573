import styled from "styled-components";
import RwButton from "./RwButton";
import React from "react";
import {arrowBack} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

const BackButton = styled(RwButton)`
    --border-color: black;
    --border-width: 0 0 2px 0;
    --border-style: solid;
`;

const RwBackButton: React.FC<{parentId: string}> = props => {

    return (
        <BackButton routerLink={`/area/${props.parentId}`} routerDirection="back">
            <IonIcon icon={arrowBack} slot="start" />
            BACK
        </BackButton>
    )
};

export default RwBackButton;
