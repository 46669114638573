import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Profile} from "../types";
import {RootState} from "../rootReducer";
import {configLoaded} from "./configSlice";

const profileAdapter = createEntityAdapter<Profile>();

const profileSlice = createSlice({
    name: 'profile',
    initialState: profileAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(configLoaded, (state, action) => {
                profileAdapter.setAll(state, action.payload.profiles);
            })
    }
});

export const profileSelectors = profileAdapter.getSelectors((state: RootState) => state.profile);

export default profileSlice.reducer;
