import {delay, filter, map, mapTo, mergeMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {combineEpics, Epic, ofType} from "redux-observable";
import {setProfileId} from "../panel/panelSlice";
import {code, informationCircle} from "ionicons/icons";
import {addHint, removeAllHints, removeHint} from "./hintsSlice";
import {profileSelectors} from "../config/profileSlice";
import {Profile} from "../types";

// This epic handles automatic dismissals of hints
const timeoutEpic: Epic = action$ => action$
    .pipe(
        // take only hint adds
        ofType(addHint),

        // Ignore hints without timeouts
        filter(action => action.payload.duration > 0),

        // Map to new observable
        mergeMap(action =>

            // Create observable of remove hint
            of(removeHint(action.payload.id))
                .pipe(

                    // Delay it by duration
                    delay(action.payload.duration)
                )
        ),
    );

// This epic handles welcome hints (for now) on logging in
const welcomeEpic: Epic = (action$, state$) => action$
    .pipe(
        // Take only panel authorized events
        ofType(setProfileId),

        // Ignore unauthorized
        filter(action => action.payload.trim() !== ""),

        // Include a small delay
        delay(500),

        // Get current state
        withLatestFrom(state$),

        // Get current profile
        map(([{payload}, state]) => profileSelectors.selectById(state, payload) as Profile),

        // Map to welcome hint
        map((profile) => addHint({
            icon: profile.isDeveloper ? code : informationCircle,
            color: profile.isDeveloper ? 'success' : 'primary',
            text: `Logged in as ${profile.name}`,
            duration: 5000
        }))
    );

// This epic removes all hints when the panel is locked
const lockEpic: Epic = action$ => action$
    .pipe(
        // Take only panel id sets
        ofType(setProfileId),

        // Ignore all except log out
        filter(action => action.payload === ""),

        // Map to clear all
        mapTo(removeAllHints())
    );

// Combine epics
const hintsEpic = combineEpics(
    timeoutEpic,
    welcomeEpic,
    lockEpic
);

export default hintsEpic;
