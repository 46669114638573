import React from 'react';
import {Area} from "../../store/types";
import {useChildren} from "../../hooks/areaHook";
import RwContainer from "../RwContainer/RwContainer";
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList} from "@ionic/react";
import styled from "styled-components";
import {useMediaQuery} from "beautiful-react-hooks";
import RwFloorPlan from "../RwFloorPlan/RwFloorPlan";

const Card = styled(IonCard)`
    --background: white;
    
    @media only screen and (min-width: 1024px) {
        display: inline-block;
        margin: auto;
        width: 80vw;  
    }
    
    ion-item {
      --background: white;
    }
`;



// This is the page type for navigation areas
// This area type only shows sub areas, no control provided
// This page will render a floor plan on large displays, or a list on small displays
const RwNavigationArea: React.FC<{area: Area}> = ({ area }) => {

    // Get sub-areas
    const subAreas = useChildren(area);

    // Check media query
    const showFloorplan = useMediaQuery('(min-width: 1024px)') && area.floorPlan;



    return (
        <RwContainer>
            <Card>
                {!area.allowAdjacent &&
                    <IonCardHeader>
                        <IonCardTitle>{area.name}</IonCardTitle>
                    </IonCardHeader>
                }

                <IonCardContent>
                    {showFloorplan ?
                            <RwFloorPlan areas={subAreas} floorPlan={area.floorPlan}>
                                {/* other non area objects can go in here */}
                            </RwFloorPlan>
                        :
                        <IonList lines="none">
                            {
                                subAreas.map(a => <IonItem routerLink={a.id} routerDirection="forward"
                                                           key={a.id}>{a.name}</IonItem>)
                            }
                        </IonList>
                    }
                </IonCardContent>
            </Card>
        </RwContainer>
    );
};

export default RwNavigationArea;
