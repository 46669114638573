import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../rootReducer";

export interface PanelState {
    Authorized: boolean;
    ProfileId: string;
    NoticeTitle?: string;
    NoticeMessage?: string;
}

export const initialState: PanelState = {
    Authorized: false,
    ProfileId: ""
};

const panelSlice = createSlice({
    name: 'panel',
    initialState,
    reducers: {
        setProfileId(state, action: PayloadAction<string>) {
            state.ProfileId = action.payload;
            state.Authorized = (action.payload.trim() !== "");
        },
    }
});

// Extract action creators and reducer
const { actions, reducer } = panelSlice;

// Extract and export action creators
export const { setProfileId } = actions;

// Export the reducer
export default reducer;

// Export selectors
export const selectIsAuthorized = (state: RootState) => state.panel.Authorized;

export const selectProfileId = (state: RootState) => state.panel.ProfileId;
