import React from "react";
import styled from "styled-components";
import {IonIcon, IonRouterLink} from "@ionic/react";

const HeaderButton = styled(IonRouterLink)`
    display: inline-block;
    background: transparent;
    color: dimgray;
    margin: 0 0.25em;
    padding: 0.35em 0.35em;
    font-size: 1.3em;
    text-align: center;
    height: var(--rw-header-button-size);
    
    &:focus {
        outline: none;
    }
    
    .label {
        margin: 0;
        font-size: 0.5em;
    }
    
    &:after {
        transition: transform 400ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        padding-top: 0.4em;
        border-bottom: 2px solid #ce0000ff;
        transform: scaleX(0);
    }
    
    &.active:after {
      transform: scaleX(1.4);
    }
`;

interface RwHeaderButtonProps {
    route?: string;
    icon: string;
    label: string;
    active?: boolean;
    onClick?: React.MouseEventHandler
}

const RwHeaderButton: React.FC<RwHeaderButtonProps> = (props) => {
    return (
            <HeaderButton onClick={props.onClick} routerLink={props.route} routerDirection="root" className={props.active ? 'active' : ''}>
                <IonIcon icon={props.icon} />
                <p className="label">{props.label}</p>
            </HeaderButton>
    );
};

export default RwHeaderButton;
