import React from "react";
import {IonApp, IonContent, IonPage} from "@ionic/react";

const Sandbox: React.FC = () => {
    return (
        <IonApp style={{"backgroundColor": "white"}}>
            <IonPage>
                <IonContent>

                    {/* PLACE SANDBOX CONTENT HERE */}

                </IonContent>
            </IonPage>
        </IonApp>
    );
};

export default Sandbox;
