import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {RootState} from "../rootReducer";
import {delay, map, mergeMap, retryWhen, tap, timeout, withLatestFrom} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {denormalize, normalize} from "normalizr";
import {configSchema} from "../schema";
import {
    configLoaded,
    fetchConfig,
    NormalizedConfig,
    panelConnected,
    panelDisconnected,
    saveConfig,
    SystemStates
} from "./configSlice";
import {areaSelectors} from "./areaSlice";
import {zoneSelectors} from "./zoneSlice";
import {profileSelectors} from "./profileSlice";
import {mixerSelectors} from "./mixerSlice";
import {loadSelectors} from "./loadSlice";
import {addHint} from "../hints/hintsSlice";
import {alertCircle, checkmarkCircle} from "ionicons/icons";

const baseUrl = 'http://192.168.200.202:41780/cws/rw-m/api';
// const baseUrl = 'http://1f08a6a78d33.ngrok.io/cws/rwm/api';

// The config epic will wait for the processor to come online via retries
const fetchConfigEpic: Epic = (action$, state$: StateObservable<RootState>, { store }) => action$
    .pipe(
        // Take fetch config only
        ofType(fetchConfig),

        // Map to new observable
        mergeMap(() =>

            // Perform request
            ajax.getJSON(`${baseUrl}/config/config`)
                .pipe(
                    // Configure timeout for http request
                    timeout(5000),

                    // Configure retry (indefinite)
                    retryWhen(errors => errors
                        .pipe(
                            // Dispatch a disconnect action on disconnect
                            tap(() => {
                                if (state$.value.config.SystemState === SystemStates.Connected)
                                    store.dispatch(panelDisconnected());
                            }),

                            // Configure delay between retries
                            delay(10000)
                        )),

                    // Map to normalized data
                    map((response: any) => {

                        // Pull relevant data
                        const data = response.message[0];

                        // Normalize data
                        const normalized = normalize<any,NormalizedConfig>
                        (data.Config, configSchema);

                        // If not connected, we dispatch a connected action
                        if (state$.value.config.SystemState !== SystemStates.Connected)
                            store.dispatch(panelConnected());

                        // Get file create
                        const created = response.message[1].FileCreation;

                        // Map to a config loaded action
                        return configLoaded({creation: created, ...normalized.entities});
                    }),

                    // Only dispatch config loaded if the config has changed
                    //filter((action) => action.payload.creation !== state$.value.config.ConfigDate),
                )
        )
    );

// The save config epic will post data back up to the server
const saveConfigEpic: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        // Take save config only
        ofType(saveConfig),

        // Get latest
        withLatestFrom(state$),

        map(([, state]) => {

            // Get ids for input
            const input = {
                areas: areaSelectors.selectIds(state),
                profiles: profileSelectors.selectIds(state)
            };

            // Get entities
            const entities = {
                areas: areaSelectors.selectEntities(state),
                zones: zoneSelectors.selectEntities(state),
                mixers: mixerSelectors.selectEntities(state),
                loads: loadSelectors.selectEntities(state),
                profiles: profileSelectors.selectEntities(state),
            };

            // Denormalize
            return denormalize(input, configSchema, entities);
        }),

        // Map to new observable
        mergeMap(data =>

            // Perform request
            ajax.post(`${baseUrl}/config/config`, JSON.stringify(data), { 'Content-Type': 'text/plain' })
                .pipe(
                    // Configure timeout for http request
                    timeout(5000),

                    // Map to normalized data
                    map(({response}) => {

                        // Pull relevant data
                        const result = response.message[0].WriteFileSuccess;

                        // Show hint based on result
                        if (!result) {
                            return addHint({
                                icon: alertCircle,
                                color: 'danger',
                                text: `Unable to save config data!`,
                            });
                        } else {
                            return addHint({
                                icon: checkmarkCircle,
                                color: 'success',
                                text: `Config updated successfully!`,
                                duration: 1500
                            });
                        }
                    }),
                )
        )
    );

// Combine epics
const configEpic = combineEpics(
    fetchConfigEpic,
    saveConfigEpic,
);

export default configEpic;
