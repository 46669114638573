import React from "react";
import RwContainer from "../../components/RwContainer/RwContainer";
import {IonIcon, IonText} from "@ionic/react";
import RwButton from "../../components/RwButton/RwButton";
import {home} from "ionicons/icons";

const NotFound: React.FC = () => {
    return (
        <RwContainer>
            <IonText color="danger">
                PAGE NOT FOUND
            </IonText>

            <br />
            <br />

            <RwButton routerLink="/" routerDirection="none">
                <IonIcon icon={home} slot="start" />
                GO HOME
            </RwButton>
        </RwContainer>
    );
};

export default NotFound;
