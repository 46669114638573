import styled from "styled-components";

// The RwContainer is a general purpose div that centers content on the page
const RwContainer = styled.div`
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

export default RwContainer;
