import {schema} from 'normalizr';

// Define mixer config
export const mixer = new schema.Entity('mixers');

// Define load config
export const load = new schema.Entity('loads');

// Define zone config
export const zone = new schema.Entity('zones', {
    mixers: [mixer],
    lights: {
        loads: [load]
    },
    shades: {
        loads: [load]
    }
});

// Define area config
export const area = new schema.Entity('areas', {
    zones: [zone]
});

export const profile = new schema.Entity('profiles');

// Handle circular ref
area.define({areas: [area]});

// export scheme
export const configSchema = {areas: [area], profiles: [profile]};
