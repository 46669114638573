import React from "react";
import RwPage from "../../components/RwPage/RwPage";
import RwContainer from "../../components/RwContainer/RwContainer";
import {IonCardContent, IonIcon, IonProgressBar, IonText, useIonViewWillEnter, useIonViewWillLeave} from "@ionic/react";
import {lockClosed} from "ionicons/icons";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {setProfileId} from "../../store/panel/panelSlice";
import {useHistory} from "react-router";
import {RwCard} from "../../components/RwCard/RwCard";

const Card = styled(RwCard)`
    
    @media only screen and (min-width: 1024px) {
        display: inline-block;
        margin: auto;
        width: 40vw;  
    }
    
    ion-item {
      --background: white;
    }
`;

// This component will show a brief intermediate section before locking
const Lock: React.FC = () => {

    // Dispatch
    const dispatch = useDispatch();

    // Router access
    const history = useHistory();

    // Lock function
    const lock = () => {

        // Route
        history.replace('/');

        // Clear profile
        dispatch(setProfileId(""));

    };

    // reference to timeout
    let timeout = 0;

    // setup timeout
    useIonViewWillEnter(() => timeout = setTimeout(lock, 2000));

    // clear on exit
    useIonViewWillLeave(() => clearTimeout(timeout));

    return (
        <RwPage>
            <RwContainer>
                <Card onClick={() => history.goBack()}>
                    <IonCardContent>
                        <IonIcon icon={lockClosed} color="dark" size="large" />

                        <IonText color="dark">
                            <h1>
                                Locking
                            </h1>
                        </IonText>

                        <IonText color="medium">
                            <p>
                                Tap to cancel
                            </p>
                        </IonText>

                    </IonCardContent>

                    <IonProgressBar color="rw" type="indeterminate" />

                </Card>
            </RwContainer>
        </RwPage>
    );
};

export default Lock;
