import React, {useState} from 'react';
import styled from "styled-components";
import {IonButton, IonCard, IonCardHeader, IonCol, IonIcon, IonItem, IonRow} from "@ionic/react";
import {close, options} from "ionicons/icons";

// Footer item for cards
// Can be stacked
const RwCardFooter = styled(IonItem).attrs({lines: 'none', color: 'none'})`
  border-top: 1px solid var(--ion-color-light);
  box-sizing: content-box;
  
  display: flex;
  align-items: center;
  
  .ion-text-wrap {
    font-size: 0.7em;
  }
`;

// Styled card
const RwCard = styled(IonCard)`
    --background: white;
    height: 100%;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    
    ion-card-content {
      flex-grow: 1;
    }
    
    ${RwCardFooter}:last-child {
      min-height: 5em;
    }
`;

// Styled header
const CardHeader = styled(IonCardHeader)`
  border-bottom: 1px solid var(--ion-color-rw);
  
  // Remove padding
  padding: 10px 0;
  
  // Replace with margin (to inset border)
  margin: 0 20px;
  
  // Enforce minimum height on rows
  ion-row {
    min-height: 2.1em;
    
    // Remove padding on column children
    ion-col {
      --ion-grid-column-padding: 0;
    }
  }
  
  
`;

// Styled title
const RwCardTitle = styled.div`
  --color: var(--ion-color-medium);
  text-align: center;
  font-size: 1em;
  font-weight: normal;
`;

// Actions for use in cards
export interface RwCardAction {
    name?: string;
    icon?: string;
    action(): void;
}

// Card header
const RwCardHeader: React.FC<{title: string, actions?: RwCardAction[]}> = ({title, actions}) => {

    // state for actions popover
    const [showActions, setShowActions] = useState(false);

    // Handle optional props
    actions = actions || [];

    // Action button
    const actionButton = (action: RwCardAction, key?: any) => (
        <IonButton key={key} color="medium" size="small" fill={!action.name ? "clear" : "outline"} onClick={action.action}>
            {action.icon &&
                <IonIcon slot={!action.name ? "icon-only" : "start"} icon={action.icon} />
            }
            {action.name?.toUpperCase()}
        </IonButton>
    );

    return (
        <CardHeader>
            {/* main row*/}
            <IonRow class="ion-align-items-center">

                {/* if actions is open, show actions, otherwise, show title */}
                {showActions ?
                    <>
                        <IonCol size="9">
                            {actions.map((action, index) => actionButton(action, index))}
                        </IonCol>
                    </> :

                    <>
                        <IonCol size="3" />

                        <RwCardTitle as={IonCol} size="6">
                        {title.toUpperCase()}
                            </RwCardTitle>
                    </>
                }

                {/* show either a) nothing b) single action c) more button d) close button */}
                <IonCol class="ion-text-right" size="3">

                    {actions.length > 0 &&
                        actions.length > 1
                            ? actionButton({icon: showActions ? close : options, action: () => setShowActions(!showActions)
                            })
                            : actionButton(actions[0])

                    }

                </IonCol>
            </IonRow>

        </CardHeader>
    );
};



export {RwCard, RwCardHeader, RwCardFooter};
