import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Area} from "../../store/types";
import useSize from "@react-hook/size";
import {useDispatch} from "react-redux";
import {useDrop, XYCoord} from "react-dnd";
import {areaUpdated} from "../../store/config/areaSlice";
import RwFloorPlanArea from "./RwFloorPlanArea";
import RwButton from "../RwButton/RwButton";
import {useProfile} from "../../hooks/profileHook";
import {fetchConfig, saveConfig} from "../../store/config/configSlice";
import {IonIcon, isPlatform} from "@ionic/react";
import {pencil} from "ionicons/icons";

// Styling for the floor plan container
const FloorPlan = styled.div`
  
  display: inline-block;
  position: relative;
  
  img {
    height: 50vh;
  }
`;

// The edit buttons are placed in the top right
const EditContainer = styled.div`
  position: absolute;
`;

interface DragItem {
    type: string
    area: Area
}

// This object handles editable floor plans for developer mode
const EditableFloorPlan: React.FC = ({ children }) => {

    // Get floor plan dom ref
    const ref = useRef(null);

    // Get floor plan size
    const [width, height] = useSize(ref);

    // Get dispatch
    const dispatch = useDispatch();

    // Setup drops for areas
    const [, drop] = useDrop({
        accept: 'area',
        drop(item: DragItem, monitor) {

            // Convert pixels to percentage
            const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
            const left = Math.round(item.area.position.x + (delta.x / width) * 100);
            const top = Math.round(item.area.position.y + (delta.y / height) * 100);

            // Dispatch update area
            dispatch(areaUpdated({ id: item.area.id, changes: {
                    position: {
                        slot: item.area.position.slot,
                        x: left,
                        y: top,
                    },
                }}));

            return undefined;
        }
    });

    // Combine references
    drop(ref);

    return (
            <FloorPlan ref={ref}>
                { children }
            </FloorPlan>
    );
};

// This is the floor plan wrapper that handles editable / non editable floor plans
const RwFloorPlan: React.FC<{floorPlan: string, areas: Area[]}> = ({ floorPlan, children, areas }) => {

    // Internal state for edit mode
    const [edit, setEdit] = useState(false);

    // Dispatch
    const dispatch = useDispatch();

    // Save and discard functions
    const discardChanges = () => {

        // Disable edit mode
        setEdit(false);

        // Reload config
        dispatch(fetchConfig);
    };

    // Save and discard functions
    const saveChanges = () => {

        // Disable edit mode
        setEdit(false);

        // save config
        dispatch(saveConfig);
    };

    // Conditional wrapper
    const Wrapper = edit ? EditableFloorPlan : FloorPlan;

    // Get profile
    const profile = useProfile();

    // Edit controls
    const editControls = edit ?
        (
            <>
                <RwButton color="success" onClick={saveChanges}>
                    Save
                </RwButton>

                <RwButton color="danger" onClick={discardChanges}>
                    Discard
                </RwButton>
            </>
        ) :
        (
            <RwButton onClick={() => setEdit(true)}>
                <IonIcon icon={pencil} slot="start" />
                Edit
            </RwButton>
        );

    return (
        <Wrapper>
            { profile.isDeveloper && isPlatform('desktop') &&
                <EditContainer>
                    { editControls }
                </EditContainer>
            }

            {/* background image */}
            <img alt="floor plan" src={require(`../../assets/floorplans/${floorPlan}`)} />

            {/* areas */}
            {
                areas.map(a =>
                    <RwFloorPlanArea edit={edit} key={a.id} area={a} />)
            }

            {/* any other children */}
            {children}
        </Wrapper>
    );
};

export default RwFloorPlan;
