import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Zone} from "../types";
import {RootState} from "../rootReducer";
import {configLoaded} from "./configSlice";

const zoneAdapter = createEntityAdapter<Zone>();

const zoneSlice = createSlice({
    name: 'zone',
    initialState: zoneAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(configLoaded, (state, action) => {
                zoneAdapter.setAll(state, action.payload.zones);
            })
    }
});

export const zoneSelectors = zoneAdapter.getSelectors((state: RootState) => state.zone);

export default zoneSlice.reducer;
