import {IonCol, IonGrid, IonRow} from '@ionic/react';
import React from 'react';
import RwPage from "../../components/RwPage/RwPage";
import {useParams} from "react-router";
import NotFound from "../NotFound/NotFound";
import RwBackButton from "../../components/RwButton/RwBackButton";
import {useArea} from "../../hooks/areaHook";
import RwSiblings from "../../components/RwHeader/RwSiblings";
import {PageType} from "../../store/types";
import RwNavigationArea from "../../components/RwArea/RwNavigationArea";

const Area: React.FC = () => {

    // Get params
    const {id} = useParams();

    // Connect to redux for config data

    // Get this area
    const area = useArea(id);

    // Render area view conditionally based on pagetype in config
    const areaView = () => {
        switch (area.pageType) {
            case PageType.Navigation:
                return <RwNavigationArea area={area} />;

            default:
                return <>INVALID AREA</>
        }
    };

    return (
        <RwPage>

            {
                area ? (
                    <>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="2">
                                    { area.parentId &&
                                        <RwBackButton parentId={area.parentId} />
                                    }
                                </IonCol>

                                <IonCol size="8" className="ion-text-center">
                                    { area.allowAdjacent &&
                                        <RwSiblings area={area}/>
                                    }
                                </IonCol>

                                <IonCol size="2" />
                            </IonRow>
                        </IonGrid>

                        {
                            areaView()
                        }

                    </>
                ) : <NotFound />
            }

        </RwPage>
    );
};

export default Area;
