import {IonButton} from "@ionic/react";
import styled from "styled-components";

const RwButton = styled(IonButton).attrs(() => ({
    mode: 'md'
}))<{active?: boolean}>`
    --background: white;
    --border-radius: 0px; 
    --border-color: var(--ion-color-rw);
    --border-style: solid;
    
    --color: ${props => props.active ? 'var(--ion-color-rw)' : 'black'};
    --border-width: ${props => props.active ? '0 0 2px 0' : '0'};
    ${props => props.active ? '--ripple-color: var(--ion-color-rw);' : ''}
`;

export default RwButton;
