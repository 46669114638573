import app from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyCHzKeFl1AduNvXGZyvvDi-6UuEjreClEA",
    authDomain: "resorts-world-ui.firebaseapp.com",
    databaseURL: "https://resorts-world-ui.firebaseio.com",
    projectId: "resorts-world-ui",
    storageBucket: "resorts-world-ui.appspot.com",
    messagingSenderId: "177417656689",
    appId: "1:177417656689:web:5e78fafbeba1f3b9963ed0",
    measurementId: "G-WX2T0JPG25"
};

const firebase = () => app.initializeApp(firebaseConfig);

export default firebase;
