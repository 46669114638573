import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../rootReducer";

// RwHints are auto id'd
let nextHintId = 0;

export interface Hint extends HintOptions{
    id: number;
}

export interface HintOptions {
    icon: string;
    color: string;
    duration?: number;
    text: string;
}

export const initialState: Hint[] = [];

const hintsSlice = createSlice({
    name: 'hints',
    initialState,
    reducers: {
        addHint: {
            reducer(state, action: PayloadAction<Hint>) {
                state.push(action.payload);
            },
            prepare(hint: HintOptions) {
                return { payload: {...hint, id: nextHintId++}}
            }
        },
        removeHint(state, action: PayloadAction<number>) {
            return state.filter(hint => hint.id !== action.payload);
        },
        removeAllHints() {
            return [];
        }
    }
});

// Extract action creators and reducer
const { actions, reducer } = hintsSlice;

// Extract and export action creators
export const { addHint, removeHint, removeAllHints } = actions;

// Export the reducer
export default reducer;

// Export selectors
export const selectHints = (state: RootState) => state.hints;
