import {IonContent, IonPage} from '@ionic/react';
import React from 'react';
import styled from "styled-components";

const Content = styled(IonContent)`
  --padding-top: var(--rw-header-size);
  --ion-background-color: var(--ion-color-rw-bg);
`;

// Handles boilerplate for ion page, content, and common components
const RwPage: React.FC = props => {

    return (
        <IonPage>

            <Content scrollY={false}>

                {/* content */}
                { props.children }

            </Content>
        </IonPage>
    );
};

export default RwPage;
