import React from "react";
import {Area} from "../../store/types";
import {useSiblings} from "../../hooks/areaHook";
import RwButton from "../RwButton/RwButton";

const RwSiblings: React.FC<{area: Area}> = ({area}) => {

    // Get siblings
    const siblings = useSiblings(area);

    return (
        <>
            {
                siblings.map(sibling =>
                (
                    <RwButton active={area.id === sibling.id} key={sibling.id} routerLink={sibling.id}
                              routerDirection="none">
                        {sibling.name}
                    </RwButton>
                ))
            }
        </>
    )
};

export default RwSiblings;
