import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";

import * as serviceWorker from './serviceWorker';

import store from "./store/store";

import {setupConfig} from '@ionic/react';
import {IsCrestronEnvironment} from "@pulsegroup/resorts-world-lib";

import firebase from './firebase';

firebase();

// Switch if running on device
if (IsCrestronEnvironment()) {
    // Handle viewport scaling on small touchpanels
    const viewportMeta = document.getElementById('viewport-meta');

    // @ts-ignore
    let content = viewportMeta.getAttribute('content');

    // @ts-ignore
    content = content.replace(/1.0/g, '1.0');

    // @ts-ignore
    viewportMeta.setAttribute('content', content);
}

// Add console hook only if debug
if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && IsCrestronEnvironment()) {
    const script: any = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://remotejs.com/agent/agent.js';
    script.setAttribute('data-consolejs-channel', 'fa29e36a-54a8-c660-a625-45de8768ef6f');

    document.head.appendChild(script);
}

setupConfig({
    mode: 'ios'
});

const render = () => {
    const App = require('./app/App').default;

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    )
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./app/App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
