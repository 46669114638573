// Interfaces for all types

interface BaseObject {
    id: string;
    name: string;
}

interface AsyncValue<T> {
    desired: T;
    reported: T;
}

// Position on parent floorplan
interface Position {
    x: number;
    y: number;
    slot: string;
}

export enum PageType {
    None,
    Navigation = "Navigation",
    Routing = "Routing",
    RoomControl = "RoomControl",
    RoomCombiner = "RoomCombiner"
}

export interface Mixer extends BaseObject {
    level: AsyncValue<number>;
    mute: AsyncValue<boolean>;
    phantomPower: AsyncValue<boolean>;
    micLevel: AsyncValue<boolean>;
    // TODO: Patching
}

export interface Load extends BaseObject {
    level: AsyncValue<number>;
}

export interface Zone extends BaseObject {
    audio: {
        level: AsyncValue<number>;
        mute: AsyncValue<boolean>;
        source: AsyncValue<number>;
    }

    // TODO: Sources will need more work
    video: {
        source: AsyncValue<number>;
        power: AsyncValue<boolean>;
    }

    mixers: string[];

    lights: {
        level: AsyncValue<number>;
        presets: string[]; // TODO: Object?
        loads: string[]
    }

    shades: {
        level: AsyncValue<number>;
        presets: string[]; // TODO: Object?
        loads: string[]
    }
}

export interface Area extends BaseObject {
    pageType: PageType;
    floorPlan: string;
    position: Position;
    parentId: string;
    allowAdjacent: boolean;
    areas: string[];
    zones: string[];
}

export interface Profile extends BaseObject {
    rootAreaId: string;
    isDeveloper: boolean;
    pin: string;
}
