import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Area, Load, Mixer, Profile, Zone} from "../types";


// System state is updated when we receive a failed or successful response
export class SystemStates {
    static None = 'none';
    static Disconnected = 'disconnected';
    static Connected = 'connected';
}

// This is the interface for normalized data from the config server
export interface NormalizedConfig {
    areas: { [key: string]: Area }
    zones: { [key: string]: Zone }
    mixers: { [key: string]: Mixer }
    loads: { [key: string]: Load }
    profiles: { [key: string]: Profile }
    creation: string
}

const initialState = {
    ConfigLoaded: false,
    ConfigDate: '',
    SystemState: SystemStates.None
};

// This action triggers a connection via the config epic
// The epic will retrigger until it succeeds
export const fetchConfig = createAction('panel/fetchConfig');

// This action posts data back up to the server for saving
export const saveConfig = createAction('panel/saveConfig');

// The config slice
// configLoaded: dispatched when the config is loaded or changed
// panelConnected: dispatched when the system changes to connected
// panelDisconnected: dispatched when the system changes to disconnected
const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        configLoaded(state, action: PayloadAction<NormalizedConfig>) {
            state.ConfigLoaded = true;
            state.ConfigDate = action.payload.creation;
        },
        panelConnected(state) {
            state.SystemState = SystemStates.Connected;
        },
        panelDisconnected(state) {
            state.SystemState = SystemStates.Disconnected
        }
    },
});

// Extract action creators and reducer
const { actions, reducer } = configSlice;

// Extract and export action creators
export const { configLoaded, panelConnected, panelDisconnected } = actions;

// Export the reducer
export default reducer;


