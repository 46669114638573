import {combineReducers} from "@reduxjs/toolkit";
import configReducer from "./config/configSlice";
import panelReducer from './panel/panelSlice';
import areaReducer from './config/areaSlice';
import zoneReducer from './config/zoneSlice';
import mixerReducer from './config/mixerSlice';
import loadReducer from './config/loadSlice';
import profileReducer from './config/profileSlice';
import hintsReducer from './hints/hintsSlice';

const rootReducer = combineReducers({
    config: configReducer,
    panel: panelReducer,
    area: areaReducer,
    zone: zoneReducer,
    mixer: mixerReducer,
    load: loadReducer,
    hints: hintsReducer,
    profile: profileReducer
});

// Type inference for typescript
export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
