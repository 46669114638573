import {useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {Profile} from "../store/types";
import {profileSelectors} from "../store/config/profileSlice";
import {selectProfileId} from "../store/panel/panelSlice";

export const useProfile = () => {

    // Get current profile id
    const profileId = useSelector(selectProfileId);

    // Get profile
    const profile = useSelector<RootState>(state => profileSelectors.selectById(state, profileId));

    return profile as Profile;
};
