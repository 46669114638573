import {combineEpics} from "redux-observable";
import configEpic from "./config/configEpic";
import hintsEpic from "./hints/hintsEpic";

const rootEpic = combineEpics(
    configEpic,
    hintsEpic
);

export default  rootEpic;
