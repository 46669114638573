import React, {useState} from "react";
import RwPage from "../../components/RwPage/RwPage";
import {RwCard, RwCardAction, RwCardFooter, RwCardHeader} from "../../components/RwCard/RwCard";
import {IonButton, IonCardContent, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonTabButton,} from "@ionic/react";
import styled from "styled-components";
import {document, musicalNote, send, shuffle, tv} from "ionicons/icons";

// This is a full screen grid
const Grid = styled(IonGrid)`
  height: 100%;
  
  & > ion-row {
    height: 100%;
    
    & > ion-col {
      height: 80vh;
    }
  }
`;

// Tab button styling
const TabButton = styled(IonTabButton)`
  --color-selected: var(--ion-color-rw);
`;

// Tab definition
const tabs = [
    {
        name: 'VIDEO',
        icon: tv,
        inputComponent: <IonLabel>VIDEO INPUT</IonLabel>,
        outputComponent: <IonLabel>VIDEO OUTPUT</IonLabel>
    },
    {
        name: 'AUDIO',
        icon: musicalNote,
        inputComponent: <IonLabel>AUDIO INPUT</IonLabel>,
        outputComponent: <IonLabel>AUDIO OUTPUT</IonLabel>
    },
    {
        name: 'MISC 1',
        icon: shuffle,
        inputComponent: <IonLabel>MISC 1 INPUT</IonLabel>,
        outputComponent: <IonLabel>MISC 1 OUTPUT</IonLabel>
    },
    {
        name: 'MISC 2',
        icon: shuffle,
        inputComponent: <IonLabel>MISC 2 INPUT</IonLabel>,
        outputComponent: <IonLabel>MISC 2 OUTPUT</IonLabel>
    }
];

// Top level routing page
const Routing: React.FC = () => {

    // Current tab
    const [currentTab, setTab] = useState(0);

    // Temp - input actions
    const inputActions: RwCardAction[] = [{
        name: 'report',
        icon: document,
        action: () => console.log('send report')
    }];

    // Temp - output actions
    const outputActions: RwCardAction[] = [
        {
            name: 'action 1',
            icon: document,
            action: () => console.log('send report')
        },
        {
            name: 'action 2',
            action: () => console.log('clear all')
        },
    ];

    return (
        <RwPage>
            <Grid>
                {/* grid used to align and space out two cards */}
                <IonRow class="ion-justify-content-around ion-align-items-center">
                    <IonCol size="6" size-xl="5">

                        {/* Inputs card */}
                        <RwCard>

                            <RwCardHeader title="inputs" actions={inputActions} />

                            {/* Content is dynamic based on tab */}
                            <IonCardContent>
                                {tabs[currentTab].inputComponent}
                            </IonCardContent>

                            {/* first footer, control source */}
                            <RwCardFooter>
                                <IonLabel class="ion-text-wrap" color="medium">
                                    Press to control selected source
                                </IonLabel>

                                <IonButton slot="end" fill="outline" color="rw">
                                    CONTROL SOURCE
                                </IonButton>
                            </RwCardFooter>

                            {/* main footer, tabs */}
                            <RwCardFooter as="div">

                                {tabs.map((tab, index) =>
                                    <TabButton selected={currentTab === index} tab={tab.name} onClick={() => setTab(index)}>
                                        <IonIcon icon={tab.icon} />
                                        <IonLabel>{tab.name}</IonLabel>
                                    </TabButton>
                                )}

                            </RwCardFooter>
                        </RwCard>
                    </IonCol>

                    {/* Outputs card */}
                    <IonCol size="6" size-xl="5">
                        <RwCard>

                            <RwCardHeader title="outputs" actions={outputActions} />

                            {/* Content is dynamic based on tab on inputs card */}
                            <IonCardContent>
                                {tabs[currentTab].outputComponent}
                            </IonCardContent>

                            {/* main footer, take button */}
                            <RwCardFooter as={IonRow}>
                                <IonCol size="7" class="ion-text-center ion-align-self-center">
                                    <IonLabel class="ion-text-wrap" color="medium">
                                        Press to apply changes
                                    </IonLabel>
                                </IonCol>

                                <IonCol size="5">
                                    <IonButton size="default" expand="block" color="rw" onClick={() => console.log('did take')}>
                                        TAKE

                                        <IonIcon slot="end" icon={send}/>
                                    </IonButton>
                                </IonCol>
                            </RwCardFooter>
                        </RwCard>
                    </IonCol>
                </IonRow>
            </Grid>

        </RwPage>
    );
};

export default Routing;
