import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit"

import rootReducer from './rootReducer';
import rootEpic from './rootEpic';
import {createEpicMiddleware} from "redux-observable";
import {BehaviorSubject} from "rxjs";
import {switchMap} from "rxjs/operators";

const epicMiddleware =  createEpicMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: [epicMiddleware, ...getDefaultMiddleware()]
});

// Hot reloading support for epics
// Create subject
const epic$ = new BehaviorSubject(rootEpic);

// Pipe root epic so we can swap out
// @ts-ignore
export const hotReloadingEpic = (...args) =>
    epic$.pipe(
        // @ts-ignore
        switchMap(epic => epic(...args, { store }))
    );

// Run root epic
// @ts-ignore
epicMiddleware.run(hotReloadingEpic);

// Hot reload root epic and reducer
if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        const newRootEpic = require('./rootReducer').rootEpic;

        store.replaceReducer(newRootReducer);
        epic$.next(newRootEpic);
    });
}

export type AppDispatch = typeof store.dispatch

export default store
