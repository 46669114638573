import {RootState} from "../store/rootReducer";
import {areaSelectors} from "../store/config/areaSlice";
import {useSelector} from "react-redux";
import {Area} from "../store/types";

export const useArea = (areaId: string) => {
    const area = useSelector<RootState>(state => areaSelectors.selectById(state, areaId));

    return area as Area;
};

export const useChildren = (area: Area) => {
    return useSelector((state: RootState) =>
        area && area.areas ? area.areas.map(a => areaSelectors.selectById(state, a)) : []
    ) as Area[];
};

export const useSiblings = (area: Area) => {

    const parent = useArea(area.parentId);

    return useChildren(parent);

    //return children.filter(a => a !== area);
};
