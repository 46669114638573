import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Load} from "../types";
import {RootState} from "../rootReducer";
import {configLoaded} from "./configSlice";

const loadAdapter = createEntityAdapter<Load>();

const loadSlice = createSlice({
    name: 'load',
    initialState: loadAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(configLoaded, (state, action) => {
                loadAdapter.setAll(state, action.payload.loads);
            })
    }
});

export const loadSelectors = loadAdapter.getSelectors((state: RootState) => state.load);

export default loadSlice.reducer;
