import {IonButton, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText} from '@ionic/react';
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import logo from '../../assets/images/logo.png'
import bg from './bg.jpg'
import {setProfileId} from '../../store/panel/panelSlice'
import {backspaceOutline, radioButtonOffOutline, radioButtonOnOutline} from "ionicons/icons";
import styled, {css, keyframes} from "styled-components";
import RwContainer from "../RwContainer/RwContainer";
import {profileSelectors} from "../../store/config/profileSlice";
import {RwCard} from "../RwCard/RwCard";

// The background image
const Background = styled.div`
    filter: blur(8px);
    -webkit-filter: blur(8px);
    
    height: 100%;
    
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${bg}) no-repeat center;
    background-size: cover;
`;

// animation for failed attempt
const failed = keyframes`
  0% { transform: translate(1px, 0px); }
  10% { transform: translate(-1px, 0px); }
  20% { transform: translate(2px, 0px); }
  30% { transform: translate(-2px, 0px); }
  40% { transform: translate(3px, 0px); }
  50% { transform: translate(-3px, 0px); }
  60% { transform: translate(2px, 0px); }
  70% { transform: translate(-2px, 0px); }
  80% { transform: translate(1px, 0px); }
  90% { transform: translate(-1px, 0px); }
`;

// Main card for login
const Card = styled(RwCard)<{failed: boolean}>`
    display: inline-block;
    margin: auto;
    
    ion-col {
      --ion-grid-column-padding: 0px;
    }
    
    img {
      width: 200px;
    }
    
    ${props => props.failed && css`animation: ${failed} 0.5s;`}
`;

// Keypad button props
interface KeypadButtonProps {
    onClick: ((event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void) | undefined
}

// Single keypad button
const KeypadButton: React.FC<KeypadButtonProps> = (props) => {
      return (
          <IonButton onClick={props.onClick} color="dark" className="ion-margin-horizontal" size="large" expand="full" fill="clear">
              {props.children}
          </IonButton>
      )
};

const RwLogin: React.FC = () => {

    // Connect for dispatching login events
    const dispatch = useDispatch();

    // Get all profiles
    const profiles = useSelector(profileSelectors.selectAll);

    // Internal state for pin code
    const [pincode, setPincode] = useState('');

    const [failed, setFailed] = useState(false);

    // Function to add to pin code
    const addToPincode = (digit: string) => {
        if (pincode.length >= 4) return;

        setPincode(pincode + digit);
        setFailed(false);
    };

    // Function to delete from pin code
    const removeFromPincode = () => {
        setPincode(pincode.substring(0, pincode.length - 1));
    };

    // Check for pin success
    useEffect(() => {
        const setProfile = (id: string) => {
            dispatch(setProfileId(id));
        };

        if (pincode.length >= 4) {
            // Check all pin codes
            const profile = profiles.filter(p => p.pin === pincode);

            // Log in if appropriate
            if (profile.length > 0)
                setProfile(profile[0].id);
            else {
                setProfile("");
                setFailed(true);
            }

            // Clear pingcode
            setPincode("");
        }
    }, [pincode, dispatch, profiles]);

    return (
        <IonPage>
            <IonContent>

                {/* The background overlay component */}
                <Background />

                {/* Centers content on the page */}
                <RwContainer>

                    <Card failed={failed}>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow class="ion-align-items-center ion-justify-content-center">
                                    <IonCol class="ion-margin-bottom">
                                        <img alt="" className="ion-margin-vertical" src={logo} />
                                    </IonCol>
                                </IonRow>
                                <IonRow class="ion-align-items-center ion-justify-content-center">
                                    <IonCol class="ion-margin-bottom">
                                        <IonText>
                                            <h3>Enter Passcode</h3>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow class="ion-align-items-center ion-justify-content-center">
                                    <IonCol size="2">
                                        <IonIcon icon={pincode.length > 0 ? radioButtonOnOutline : radioButtonOffOutline} />
                                    </IonCol>

                                    <IonCol size="2">
                                        <IonIcon icon={pincode.length > 1 ? radioButtonOnOutline : radioButtonOffOutline} />
                                    </IonCol>

                                    <IonCol size="2">
                                        <IonIcon icon={pincode.length > 2 ? radioButtonOnOutline : radioButtonOffOutline} />
                                    </IonCol>

                                    <IonCol size="2">
                                        <IonIcon icon={pincode.length > 3 ? radioButtonOnOutline : radioButtonOffOutline} />
                                    </IonCol>
                                </IonRow>

                                <IonRow class="ion-align-items-center">
                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('1')}>1</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('2')}>2</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('3')}>3</KeypadButton>
                                    </IonCol>
                                </IonRow>

                                <IonRow class="ion-align-items-center">
                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('4')}>4</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('5')}>5</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('6')}>6</KeypadButton>
                                    </IonCol>
                                </IonRow>

                                <IonRow class="ion-align-items-center">
                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('7')}>7</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('8')}>8</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('9')}>9</KeypadButton>
                                    </IonCol>
                                </IonRow>

                                <IonRow class="ion-align-items-center">
                                    <IonCol size="4">

                                    </IonCol>

                                    <IonCol size="4">
                                        <KeypadButton onClick={() => addToPincode('0')}>0</KeypadButton>
                                    </IonCol>

                                    <IonCol size="4">
                                        <IonButton onClick={removeFromPincode} color="dark" fill="clear" expand="full" size="large">
                                            <IonIcon slot="icon-only" icon={backspaceOutline} />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>


                        </IonCardContent>
                    </Card>



                </RwContainer>
            </IonContent>
        </IonPage>
    );
};

export default RwLogin;
