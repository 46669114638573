import React from "react";
import {IonCol, IonGrid, IonHeader, IonRow, IonToolbar} from "@ionic/react";
import {helpCircle, home, lockClosed, logOutOutline, settings, wifi} from "ionicons/icons";
import {useLocation} from "react-router-dom";
import RwHeaderButton from "./RwHeaderButton";
import Moment from "react-moment";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import {useProfile} from "../../hooks/profileHook";

// RwHeader styled for use with RW
const Header = styled(IonHeader).attrs(() => ({
    mode: 'md'
}))`
    --ion-grid-column-padding: 0;
    --ion-grid-padding: 3px;
    
    img {
      padding: 10px;
      float: right;
      height: var(--rw-header-button-size);
    }
`;

interface HeaderProps { }

const RwHeader: React.FC<HeaderProps> = () => {

    // Hook for router
    const location = useLocation();

    // Get current profile for root area
    const profile = useProfile();

    // Routes
    const routes = [
        { title: 'Home', link: `/area/${profile.rootAreaId}`, path: '/area', icon: home },
        { title: 'Routing', path: '/routing', icon: logOutOutline },
        { title: 'Switch', path: '/switch', icon: wifi },
        { title: 'Settings', path: '/settings', icon: settings },
        { title: 'Lock', path: '/lock', icon: lockClosed },
        { title: 'Help', path: '/help', icon: helpCircle },
    ];

    const timeFormat = "h:mm A";
    const dateFormat = "MMM Do, YYYY";

    return (
        <Header data-testid="header">
            <IonToolbar>
                <IonGrid>
                    <IonRow>

                        {/* Left column: Buttons */}
                        <IonCol size="5">

                            {
                                routes
                                    .map(r => (
                                        <RwHeaderButton key={r.title} icon={r.icon} label={r.title} route={r.link || r.path}
                                                        active={location.pathname.startsWith(r.path)} />
                                    ))
                            }

                        </IonCol>

                        {/*
                            Center column: Clock
                            Time refreshes every 60s
                            Date does not refresh, except on page load
                         */}
                        <IonCol class="ion-align-self-center ion-text-center" size="2">
                            <Moment format={timeFormat} />
                            <br />
                            <Moment interval={0} format={dateFormat} />
                        </IonCol>

                        {/* Right column: Logo */}
                        <IonCol size="5">
                            <img alt="" src={logo} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </Header>
    );
};

export default RwHeader;
