import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Area} from "../types";
import {RootState} from "../rootReducer";
import {configLoaded} from "./configSlice";


const areaAdapter = createEntityAdapter<Area>();

const areaSlice = createSlice({
    name: 'area',
    initialState: areaAdapter.getInitialState(),
    reducers: {
        areaUpdated: areaAdapter.updateOne
    },
    extraReducers: builder =>
        builder.addCase(configLoaded, (state, action) => {
            // Use set all
            areaAdapter.setAll(state, action.payload.areas);
        })

});

// Export actions
export const {
    areaUpdated
} = areaSlice.actions;

// Export selectors
export const areaSelectors = areaAdapter.getSelectors((state: RootState) => state.area);

export default areaSlice.reducer;
