import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Mixer} from "../types";
import {RootState} from "../rootReducer";
import {configLoaded} from "./configSlice";

const mixerAdapter = createEntityAdapter<Mixer>();

const mixerSlice = createSlice({
    name: 'mixer',
    initialState: mixerAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(configLoaded, (state, action) => {
                mixerAdapter.setAll(state, action.payload.mixers);
            })
    }
});

export const mixerSelectors = mixerAdapter.getSelectors((state: RootState) => state.mixer);

export default mixerSlice.reducer;
