import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {IonApp} from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import '../theme/variables.css';
import '../styles/global.css';
import SystemWaiting from "./SystemWaiting";
import SystemOnline from "./SystemOnline";
import {fetchConfig, SystemStates} from "../store/config/configSlice";
import Sandbox from "../components/__sandbox__";

const App: React.FC = () => {

    const dispatch = useDispatch();

    // Pull our system state from the store
    const {SystemState, ConfigLoaded} = useSelector(
        (state: RootState) => state.config
    );

    // Always retry the server on page load
    useEffect(() => {
       dispatch(fetchConfig());
    }, [dispatch]);

    const online = (SystemState === SystemStates.Connected && ConfigLoaded);

    if (process.env.REACT_APP_SANDBOX)
        return (
            <Sandbox />
        );

    return (
        <IonApp>
            {!online && (
                <SystemWaiting  />
            )}

            {online && (
                <SystemOnline  />
            )}
        </IonApp>
    )
};

export default App;
