import React from 'react';
import styled from "styled-components";
import RwButton from "../RwButton/RwButton";
import {Area} from "../../store/types";
import {useDrag} from "react-dnd";
import {IonIcon, IonInput} from "@ionic/react";
import {move} from "ionicons/icons";
import {useDispatch} from "react-redux";
import {areaUpdated} from "../../store/config/areaSlice";

// This is the standard, pressable button for normal use
const FloorPlanButton = styled(RwButton)<{area: Area}>`
  position: absolute;
    left: ${({area}) => area.position.x}%;
    top: ${({area}) => area.position.y}%;
    
    // Center button
    transform: translate(-50%, -50%);
`;

// This extension is for when the floor plan button is in edit mode
const EditableFloorPlanButton = styled(FloorPlanButton)`
  cursor: grab;
  --ripple-color: transparent;
`;

// This is the "cross hair" drag icon
const FloorPlanHandle = styled(IonIcon)`
    width: 20px;
    height: 20px;
    
    margin-right: 10px;
    
    border-radius: 20px;
    background-color: white;
    border: 1px solid black;
    
    opacity: 0.5;
`;

// This floor plan area button is only rendered when we enter edit mode
const EditableFloorPlanArea: React.FC<{area: Area}> = ({area}) => {

    // support for drag and drop
    const [{ isDragging }, drag] = useDrag({
        item: {area, type: 'area'},
        collect: monitor => ({isDragging: monitor.isDragging()})
    });

    const dispatch = useDispatch();

    const updateName = (name: string) => {
        if (name.length < 3 || name.length > 25)
            return;

        dispatch(areaUpdated({ id: area.id, changes: {
                name,
            }}))
    };

    // hide during drag
    if (isDragging)
        return <div ref={drag} />;


    return (
        <EditableFloorPlanButton ref={drag} area={area}>
            <FloorPlanHandle icon={move} />
            <IonInput type="text" size={area.name.length + 3} placeholder={area.name} debounce={500} minlength={3} maxlength={25} onIonChange={e => updateName(e.detail.value!)} />
        </EditableFloorPlanButton>
    )
};

// Conditionally renders draggable vs non draggable areas
const RwFloorPlanArea: React.FC<{area: Area, edit: boolean}> = ({area, edit}) => {

    return (

            edit ?
                <EditableFloorPlanArea area={area} />
                :
                <FloorPlanButton area={area} routerLink={area.id} routerDirection='forward'>
                    {area.name}
                </FloorPlanButton>

    )
};

export default RwFloorPlanArea;
