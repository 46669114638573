import React from 'react';
import Lottie from 'lottie-react-web';
import animationDataLight from "../assets/animations/loader-light.json";
import {IonContent, IonText} from "@ionic/react";
import RwContainer from "../components/RwContainer/RwContainer";

const SystemWaiting: React.FC = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationDataLight,
    };

    return (
        <IonContent scrollY={false}>
            <RwContainer>
                <IonText color="dark">
                    <h1>Waiting for control system:</h1>
                </IonText>

                <br />
                <br />

                <Lottie
                    options={defaultOptions}
                    width="30%"
                    height="30%"
                />
            </RwContainer>
        </IonContent>
    );
};

export default SystemWaiting;
