import React from "react";
import styled from "styled-components";
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {close} from "ionicons/icons";
import {IonIcon, IonItem, IonLabel, IonSpinner} from "@ionic/react";
import {removeHint, selectHints} from "../../store/hints/hintsSlice";
import {useDispatch, useSelector} from "react-redux";

// Configuration
const HintEnterTime = 500;
const HintExitTime = 300;

// This is a single hint object
const Hint = styled(IonItem)<{color: string}>`
    margin: 0.5em;
    
    &.hint-enter {
        transform: translate(-200%, 0);
        
        &.hint-enter-active {
            transform: translate(0, 0);
            transition: transform ${HintEnterTime}ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    
    &.hint-exit {
        opacity: 1;
        
        &.hint-exit-active {
            opacity: 0.01;
            transition: opacity ${HintExitTime}ms linear;
        }
    }
`;

// This wraps all hints to control layout
const HintsContainer = styled.div`
    z-index: 1;
    position: absolute;
    top: var(--rw-header-size);
    left: 0;
`;

// This is the main hints component
const RwHints: React.FC = () => {

    // Dispatch for dismissing events
    const dispatch = useDispatch();

    // Connect for showing new hints
    const hints = useSelector(selectHints);

    // Dismiss will remove a hint
    const dismiss = (id: number) => {
        dispatch(removeHint(id));
    };

    // List of current hints
    const items = hints
        .map(h => (
            <CSSTransition key={h.id} classNames="hint" timeout={{ enter: HintEnterTime, exit: HintExitTime }}>
                <Hint button lines="none" detail={false} color={h.color}
                    onClick={() => dismiss(h.id)}>
                    <IonIcon icon={h.icon} slot="start" />

                    <IonLabel>{h.text}</IonLabel>

                    {h.duration === -1
                        ?
                        <IonSpinner slot="end" color="light" name="dots" />
                        :
                        <IonIcon icon={close} slot="end" />
                    }

                </Hint>
            </CSSTransition>
        ));

    return (
        <HintsContainer data-testid="hints">
            <TransitionGroup>
                {
                    // Hints are mapped to a list
                    // Wrapped in transition group to handle entry / exit animations
                    items
                }
            </TransitionGroup>
        </HintsContainer>
    )
};

export default RwHints;
