import {IonReactRouter} from "@ionic/react-router";
import {IonRouterOutlet} from "@ionic/react";
import {Redirect, Route} from "react-router";
import Area from "../pages/Area/Area";
import React from "react";
import {useSelector} from "react-redux";
import RwLogin from "../components/RwLogin/RwLogin";
import {selectIsAuthorized} from "../store/panel/panelSlice";
import Routing from "../pages/Routing/Routing";
import RwHeader from "../components/RwHeader/RwHeader";
import RwHints from "../components/RwHints/RwHints";
import {useProfile} from "../hooks/profileHook";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import Lock from "../pages/Lock/Lock";


const SystemOnline: React.FC = () => {

    // Pull our system state from the store
    const auth = useSelector(selectIsAuthorized);

    // Get root area
    const profile = useProfile();

    // Router for navigation
    const router = () => (
        <IonRouterOutlet>
            <Route path="/area/:id" exact>
                <Area />
            </Route>

            <Route path="/routing" exact>
                <Routing />
            </Route>

            <Route path="/lock" exact>
                <Lock />
            </Route>

            <Route path="/" exact>
                <Redirect to={`/area/${profile.rootAreaId}`} />
            </Route>
        </IonRouterOutlet>
    );

    return auth ? (
        <IonReactRouter>

            {/* header */}
            <RwHeader />

            {/* hints */}
            <RwHints />

            { /* conditionally inject drag-n-drop if dev logged in */
                profile?.isDeveloper ?
                <DndProvider backend={HTML5Backend}>
                    { router() }
                </DndProvider> :
                    router()
            }
        </IonReactRouter>
    ) : (
        <RwLogin />
        );
};

export default SystemOnline;

